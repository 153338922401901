<template>
  <v-btn
    icon
    :color="color"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-icon 
      v-if="icon"
      :size="size" 
    >
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "BaseButtonIcon",
  props: {
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'cassiopee-purple',
    },
    size: {
      type: String,
      default:'25'
    },
  },
}
</script>