<template>
  <div class="ma-10">
    <v-form>
      <v-row>
        <v-col cols="12">
          <form-step-title title="Besoins en référentiels de sécurité pour votre hébergement" />
        </v-col>
      </v-row>
      <v-row v-if="formAnswer.certifie_iso_27001 !== 'Oui' || formAnswer.certifie_hds !== 'Oui'">
        <v-col cols="12">
          <base-subtitle 
            subtitle="Si vous n'êtes pas certifié, avez-vous un besoin de certification ?"
          />
          <form-fields-radio-group 
            v-if="formAnswer.certifie_iso_27001 !== 'Oui'"
            v-model="besoin_iso_27001"
            :radio-data-list="choiceList"
            field-label="ISO 27001"
            class="mt-6"
            simple-radio-button
            @change="saveFormAnswer"
          />
          <form-fields-radio-group 
            v-if="formAnswer.certifie_hds !== 'Oui'"
            v-model="besoin_hds"
            :radio-data-list="choiceList"
            field-label="HDS"
            simple-radio-button
            @change="saveFormAnswer"
          />
        </v-col>
      </v-row>
      <v-row v-if="formAnswer.certifie_tier_uptime_institute !== 'Oui'">
        <v-col cols="12">
          <base-subtitle 
            subtitle=" Si votre(s) datacenter(s) n'est pas certifié, avez-vous un besoin de certification ?"
            class="mt-1"
          />
          <form-fields-radio-group 
            v-model="besoin_tier_uptime_institute"
            :radio-data-list="choiceList"
            field-label="Tier Uptime Institute"
            class="mt-6"
            simple-radio-button
            @change="saveFormAnswer"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import FormStepTitle from '@/components/FormStepTitle.vue'
import FormFieldsRadioGroup from '@/components/FormFieldsRadioGroup.vue'
import BaseSubtitle from '@/components/BaseSubtitle.vue'
import { mapActions, mapState, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: "SecurityReferential",
  components: {
    FormStepTitle,
    FormFieldsRadioGroup,
    BaseSubtitle 
  },
  data () {
    return { choiceList: ['Oui', 'Non', 'Ne sait pas'] };
  },
  computed: {
    ...mapFields('form', [
      'formAnswer.besoin_iso_27001',
      'formAnswer.besoin_hds',
      'formAnswer.besoin_tier_uptime_institute',
    ]),
    ...mapState('form', ['formAnswer']),
  },
  created() {
    this.fetchFormAnswer();
    this.setNextView('/needs/macro-view');
  },
  methods: {
    ...mapActions('form', ['fetchFormAnswer', 'saveFormAnswer']),
    ...mapMutations('stepper', ['setNextView']),
  },

}
</script>